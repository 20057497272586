import React, {useEffect, useRef, useState} from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import App from './App';
import { Access } from './pages/Access';
import { Error } from './pages/Error';
import { Login } from './pages/Login';
import { NotFound } from './pages/NotFound';
import { Wizard } from './pages/Wizard';

const AppWrapper = () => {
    let location = useLocation();
    const navigate = useNavigate();

    function renewToken() {
        // alert("i'm renewal");
        console.log("time to renew token");
    }

    var currentURL = window.location.href;
    console.log(currentURL);
    function extractDomainFromURL(currentUrl) {
        console.log( `currentUrl = ${currentUrl}`);
        var startPos = currentUrl.indexOf("http://");
        if( startPos !== -1 ) {
            var stopPos = currentUrl.indexOf("/", startPos + 7);
            if (stopPos >= currentUrl.length)
            {
                console.log( "Stop position is too big for the url string.");
                return "";
            } else {
                console.log( `start = ${startPos}, stop = ${stopPos}, substring = ${currentUrl.substring( startPos+7, stopPos )}` );
                var withPort = currentUrl.substring( startPos+7, stopPos );
                var portPos = withPort.indexOf(":");
                if(portPos == -1)
                    return withPort;
                else
                    return withPort.substring(0, portPos);
            }
        } else
            return "";

        return "";
    }

    var domain = extractDomainFromURL(currentURL);
    console.log(`Domain = ${domain}`);

    localStorage.setItem('serverIp', domain);

    useEffect( () => {
        let tokenInterval = setInterval( renewToken, 50000 );
        console.log("Setting interval " + tokenInterval);

        return () => { clearInterval(tokenInterval); };
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/error" element={<Error />} />
            <Route path="/notfound" element={<NotFound />} />
            <Route path="/access" element={<Access />} />
            <Route path="/wizard" element={<Wizard />} />
            <Route path="*" element={<App />} />
        </Routes>
    );
};

export default AppWrapper;
