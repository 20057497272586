import React, {useEffect, useState} from 'react';
import { Card } from 'primereact/card';
import { Timeline } from 'primereact/timeline';
import { classNames } from 'primereact/utils';
import {useNavigate} from "react-router-dom";
import {TabPanel, TabView} from "primereact/tabview";

const Jungle = () => {
    const customizedContent = (item) => {
        return (
            <Card /* title={item.status} subTitle={item.date} */>
                {item.image && <img src={`assets/demo/images/product/${item.image}`} onError={(e) => (e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png')} alt={item.name} width={200} className="shadow-2 mb-3" />}
                <p dangerouslySetInnerHTML={{ __html: item.text }}></p>
                <p>
                    {item.status}
                </p>
            </Card>
        );
    };

    const customizedMarker = (item) => {
        return (
            <span className="flex w-2rem h-2rem align-items-center justify-content-center text-white border-circle z-1 shadow-2" style={{ backgroundColor: item.color }}>
                <i className={classNames('marker-icon', item.icon)}></i>
            </span>
        );
    };

    const TimelineItemII = (t) => {
        console.log("TimelineItemII");
        console.dir(t);
        console.log("// TimelineItemII //");

        return (
            <li key={`tlItemKey-${t.status}`}>
                <div className="activity-link"></div>
                <div className="timeline-icon">
                    <i className="pi pi-calendar"></i>
                </div>
                <div className="timeline-content">
                    <h3>{t.tags}</h3>
                    <p dangerouslySetInnerHTML={{ __html: t.text }}></p>
                    <div className="timeline-footer">
                        <i className="pi pi-clock"></i>
                        <span>{t.status}</span>
                    </div>
                </div>
            </li>
        );
    }
    const TimelineItemsII = (items) => {
        console.log("TimelineItemsII");
        console.dir(items);
        console.log("// TimelineItemsII //");

        return (
            <ul>
                {
                    items.map((it) => (
                        TimelineItemII(it)
                    ))
                }
            </ul>
        );
    }

    const navigate = useNavigate();
    const [data, setData] = useState([]);
    const [tsData, setTsData] = useState([]);

    useEffect(() => {
        const retrieveHistories = () => {
            let history = [];

            const uid = localStorage.getItem('userid');
            const token = localStorage.getItem('token');

            if(!!!uid) {
                return [];
            }

            const bearString = 'Bearer ' + token;

            var serverIp = localStorage.getItem('serverIp');
            console.log(`ip = ${serverIp}`);
            fetch(`http://${serverIp}:8081/journals/getJournals`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'UserId': `${uid}`
                },
            })
                .then((response) => {
                    console.log(response.status);
                    switch(response.status) {
                        case 200:
                            return response.json();
                            break;
                        case 500:
                            localStorage.removeItem('token');
                            localStorage.removeItem('userid');
                            navigate('/login');
                            break;
                        default:
                            console.log(response.status);
                            break;
                    }
                    return response.json();
                } )
                .then((arr) => {
                    // console.log(arr);
                    setData(arr);
                })
                .catch((err) => {
                    console.log(`Exception: ${err}`);
                    localStorage.removeItem('token');
                    localStorage.removeItem('userid');
                    navigate("/login");
                });
        };

        retrieveHistories();
    }, []);

    useEffect( () => {
        // console.log("hist = " + data);
        // console.dir(data);

        const newData = data.map(v => ( {status: v.ts, color: '#607D8B',
                                         text: v.journalText.replace(/\n/g, "<br />"),
                                         tags: v.MainCategory} ) ).reverse();
        // console.dir(newData);

        setTsData(newData);
    }, [data]);

    /*
    <h5 style={{ marginTop: '5em' }}>Horizontal - Alternate Align</h5>
    <Timeline value={horizontalEvents} layout="horizontal" align="alternate" content={(item) => item} opposite={<span>&nbsp;</span>} />


                <TimelineItemsII items={tsData}></TimelineItemsII>


                            <div>
                            <li>
                                <div className="activity-link"></div>
                                <div className="timeline-icon">
                                    <i className="pi pi-globe"></i>
                                </div>
                                <div className="timeline-content">
                                    <h3>Notes Added</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean blandit tortor a ipsum vehicula, in semper sapien auctor.</p>
                                    <div className="timeline-footer">
                                        <i className="pi pi-clock"></i>
                                        <span>3 Sep 2018 at 10:41</span>
                                    </div>
                                </div>
                            </li>
                            </div>
                            <div>
                            <li>
                                <div className="activity-link"></div>
                                <div className="timeline-icon">
                                    <i className="pi pi-calendar"></i>
                                </div>
                                <div className="timeline-content">
                                    <h3>Reminder Scheduled</h3>
                                    <p>
                                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt
                                        explicabo.
                                    </p>
                                    <div className="timeline-footer">
                                        <i className="pi pi-clock"></i>
                                        <span>4 Sep 2018 at 11:30</span>
                                    </div>
                                </div>
                            </li>
                            </div>
                            <div>
                            <li>
                                <div className="activity-link"></div>
                                <div className="timeline-icon">
                                    <i className="pi pi-image"></i>
                                </div>
                                <div className="timeline-content">
                                    <div className="child">
                                        <div>
                                            <span>3 Photos Added to</span>
                                            <span className="colorful">Album-23</span>
                                        </div>
                                        <img src="assets/layout/images/dashboard/image-1.png" alt="babylon-layout" />
                                        <img src="assets/layout/images/dashboard/image-2.png" alt="babylon-layout" />
                                        <img src="assets/layout/images/dashboard/image-3.png" alt="babylon-layout" />
                                        <div className="timeline-footer">
                                            <i className="pi pi-clock"></i>
                                            <span>9 Sep 2018 at 00:44</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            </div>
                            <div>
                            <li>
                                <div className="activity-link"></div>
                                <div className="timeline-icon">
                                    <i className="pi pi-image"></i>
                                </div>
                                <div className="timeline-content">
                                    <div className="child">
                                        <h3>Location Update</h3>
                                        <img src="assets/layout/images/dashboard/antalya.png" alt="babylon-layout" style={{ width: '100%' }} />
                                        <div className="timeline-footer">
                                            <i className="pi pi-clock"></i>
                                            <span>16 Sep 2018 at 20:02</span>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            </div>
    */

    return (
        <div className="grid timeline-demo">
            <TabView className="col-12">
                <TabPanel header="Timeline I">
                    <div className="col-12">
                        <div className="card">
                            <h4>This is your resilience: </h4>
                            <h5></h5>
                            <Timeline value={tsData} align="alternate" className="customized-timeline" marker={customizedMarker} content={customizedContent} />
                        </div>
                    </div>
                </TabPanel>


                <TabPanel header="Timeline II">
                    <div className="col-12 xl:col-12">
                    <div className="card card-w-title timeline">
                        <h5>Timeline</h5>
                        { TimelineItemsII(tsData)  }
                    </div>
                </div>

                </TabPanel>
                <TabPanel header="Header III">
                    <p>
                        At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt
                        in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo
                        minus.
                    </p>
                </TabPanel>
            </TabView>
        </div>
    );
};

export default Jungle;
