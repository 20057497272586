const CryptoJS = require('crypto-js');
class AesUtil {
    keySize: Number;
    iterationCount: Number;
    constructor(keySize: number, iterationCount: Number) {
        this.keySize = keySize / 32;
        this.iterationCount = iterationCount;
    }

    public generateKey(salt: string, passPhrase: string) {
        var key = CryptoJS.PBKDF2(passPhrase, CryptoJS.enc.Hex.parse(salt), { keySize: this.keySize, iterations: this.iterationCount });
        return key;
    }

    public encrypt(salt: string, iv: string, passPhrase: string, plainText: string) {
        var key = this.generateKey(salt, passPhrase);
        var encrypted = CryptoJS.AES.encrypt(plainText, key, { iv: CryptoJS.enc.Hex.parse(iv) });
        return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    }

    public decrypt(salt: string, iv: string, passPhrase: string, cipherText: string) {
        var key = this.generateKey(salt, passPhrase);
        var cipherParams = CryptoJS.lib.CipherParams.create({
            ciphertext: CryptoJS.enc.Base64.parse(cipherText)
        });
        var decrypted = CryptoJS.AES.decrypt(cipherParams, key, { iv: CryptoJS.enc.Hex.parse(iv) });
        return decrypted.toString(CryptoJS.enc.Utf8);
    }
}

export default AesUtil;
