import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { useLocation, useNavigate } from 'react-router-dom';

import AesUtil from '../service/LoginService/AesUtil';

const CryptoJS = require('crypto-js');

export const Login = () => {
    const navigate = useNavigate();

    const [uid, setUid] = useState('');
    const [pwd, setPwd] = useState('');

    // const token = localStorage.getItem('token');
    // console.log('Login() -> ' + token);
    localStorage.removeItem('token');
    localStorage.removeItem('userid');
    // console.log('Login() -> ' + token);

    const handleSubmit = async (event) => {
        // console.dir(event);

        var iv = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);
        var salt = CryptoJS.lib.WordArray.random(128 / 8).toString(CryptoJS.enc.Hex);

        var aesUtil = new AesUtil(128, 1000);
        var ciphertext = aesUtil.encrypt(salt, iv, 'ydwGetOxdzThx6EpYcLehw', pwd);
        // console.log(`cipherText=[${ciphertext}]`);
        var aesPassword = iv + '::' + salt + '::' + ciphertext;
        // console.log(`aesPassword=[${aesPassword}]`);
        var password = window.btoa(aesPassword);
        // console.log(`password=[${password}]`);

        var cipheruid = aesUtil.encrypt(salt, iv, '12345678900987654321', uid);
        var aesUid = iv + '::' + salt + '::' + cipheruid;
        var userid = window.btoa(aesUid);

        var serverIp = localStorage.getItem('serverIp');
        console.log(`ip = ${serverIp}`);
        event.preventDefault();
        var requestUrl = `http://${serverIp}:8081/session/login`;
        console.log(`requestUrl = ${requestUrl}`);
        fetch(requestUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userid, password })
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (data.result === 'Success') {
                    localStorage.setItem('token', data.token);
                    // console.log('handleSubmit() setting userId  ' + uid);
                    localStorage.setItem('userid', uid);
                    localStorage.setItem('token', data.token);
                    navigate('/', { state: { isLoggedIn: true } });
                } else {
                    console.log('Reason of failure: ' + data.reason);
                }
            })
            .catch((err) => console.log(err));
    };

    return (
        <div className="login-body">
            <div className="login-panel"></div>

            <div className="login-content">
                <img src="assets/layout/images/logo-black.png" alt="babylon-layout" />

                <form onSubmit={handleSubmit}>
                    <h1>
                        <span>SIGN IN</span> TO BABYLON
                    </h1>
                    <p>Welcome, please use the form to sign-in.</p>

                    <div className="login-input-wrapper">
                        <InputText placeholder="Username" value={uid} onChange={(e) => setUid(e.target.value)} />
                        <i className="pi pi-user"></i>
                    </div>

                    <div className="login-input-wrapper">
                        <InputText placeholder="Password" value={pwd} onChange={(e) => setPwd(e.target.value)}  type="password"/>
                        <i className="pi pi-lock"></i>
                    </div>

                    <Button label="Sign In" type="submit" />
                </form>
            </div>
        </div>
    );
};
