import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { Chips } from 'primereact/chips';

const Dashboard = () => {
    const [AboutValues, setAboutValues] = useState([]);

    const [dropdownItem, setDropdownItem] = useState(null);
    const dropdownItems = [
        { name: 'Daily Journal', code: 'Journal' },
        { name: 'Math', code: 'Math' },
        { name: 'Language', code: 'Language' },
        { name: 'Science', code: 'Science' },
        { name: 'History', code: 'History' },
        { name: 'Art', code: 'Art' }
    ];

    const onSubjectListChange = (e) => {
        console.dir(e);
        const newValue = e.value.filter((item, index) => {
            return e.value.indexOf(item) === index;
        });
        setAboutValues(newValue);
    };

    const onDropdownChange = (e) => {
        setDropdownItem(e.value);
        const newAboutValue = [...AboutValues, e.value.name];
        const finalValues = newAboutValue.filter((item, index) => {
            return newAboutValue.indexOf(item) === index;
        });

        setAboutValues(finalValues);
    };

    const [MainCategory, setMainCategory] = useState('');
    const [User, setUser] = useState('');
    const [Text, setText] = useState('');

    function submitRememberMe(e) {
        e.preventDefault();
        console.log('submitRememberMe() called.');

        console.dir(e.target.form);

        if(AboutValues.length<=0) {
            console.log('Category is empty.');
            alert('Please choose Category.');
        } else {
            console.log( `AboutValues = ${AboutValues}`);
        }

        const uid = localStorage.getItem('userid');
        if(uid.valueOf() == '') {
            console.log('User is empty.');
            alert('Please tell me who you are.');
        }
        else
            console.log(uid);

        if(Text.valueOf().trim().length > 0) {
            const payload = {
                userid: uid,
                MainCategory: `${AboutValues}`,
                SecondaryCategory: ' ',
                JournalText: Text
            };

            var serverIp = localStorage.getItem('serverIp');
            console.log(`ip = ${serverIp}`);
            var requestUrl = `http://${serverIp}:8081/journals/recordJournal`;
            console.log(`requestUrl = ${requestUrl}`);
            fetch(requestUrl, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(payload)
            })
                .then(response => {
                    if(response.status !== 200) {
                        throw new Error('Response status was ' + response.status);
                    }
                    return response.json();
                })
                .then(data => {
                    console.log('Success:', data);
                    setText('');
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        } else {
            console.log('Content is empty.');
            alert('What you want to remember?');
        }
    }

    function handleTextInput(e) {
        if( e.target.value.length > 10000 ) {
            alert('Max length is 10000');
        }

        setText(e.target.value);
    }

    return (
        <div className="grid">
            <div className="col-12">
                <div className="card p-fluid">
                    <form>
                        <h5>What's new today?</h5>
                        <div className="field col-12">
                            <label htmlFor="name1">About</label>
                            <Dropdown id="MainCategory" value={dropdownItem} onChange={onDropdownChange} options={dropdownItems} optionLabel="name" placeholder="Please choose"></Dropdown>
                            <Chips value={AboutValues} onChange={onSubjectListChange} separator="," />
                        </div>
                        <div className="field col-12">
                            <label htmlFor="address">Happenings</label>
                            <InputTextarea id="Text" value={Text} onChange={handleTextInput}  rows="4" autoResize />
                        </div>

                        <div className="col-12 md:col-3">
                            <Button label="Remember it!" className="mr-2 mb-2" onClick={submitRememberMe}></Button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Dashboard;
